import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.gray.main,
    width: "100%",
    height: "1.5rem",
    position: "relative",
  },
  filler: {
    background: theme.palette.red.main,
    width: (props) => props.width,
    height: "100%",
  },
  percent: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    color: "black",
    margin: "0 auto 0 auto",
    width: "1.5rem",
  },
}));

export default function CompletionBar(props) {
  const classes = useStyles({ width: props.width });
  return (
    <div className={classes.container}>
      <div className={classes.filler}></div>
      <Typography className={classes.percent}>{props.width}</Typography>
    </div>
  );
}
