import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Typography, Grid } from "@material-ui/core";

// Styles
const useStyles = makeStyles((theme) => ({
  weatherDescription: {
    textTransform: "capitalize",
    textAlign: "left",
    whiteSpace: "nowrap",
  },
  itemText: {
    textAlign: "center",
    color: theme.palette.gray.main,
  },
  line: {
    width: theme.presets.width,
    background: "white",
    height: "1px",
    border: "none",
  },
}));

export default function DailyWeather({ day, iter }) {
  const classes = useStyles();
  // how many days to show (from 0 - 6)
  const numShow = 4;

  if (iter > numShow) {
    return null;
  }
  const weekdayNum = new Date(day.dt * 1000).getDay();
  const nameWeekdays = (weekdayNum) => {
    switch (weekdayNum) {
      case 0:
        return "Sun";
      case 1:
        return "Mon";
      case 2:
        return "Tue";
      case 3:
        return "Wed";
      case 4:
        return "Thu";
      case 5:
        return "Fri";
      case 6:
        return "Sat";
      default:
        return "Error";
    }
  };
  const weekday = nameWeekdays(weekdayNum);
  const high = Math.round(day.temp.max);
  const low = Math.round(day.temp.min);
  return (
    <div key={day.dt}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="subtitle1" className={classes.itemText}>
            {weekday}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1" className={classes.itemText}>
            {high}&#176; | {low}&#176;
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="subtitle1"
            className={clsx(classes.itemText, classes.weatherDescription)}
          >
            {day.weather[0].description}
          </Typography>
        </Grid>
      </Grid>
      <hr className={classes.line} />
    </div>
  );
}
