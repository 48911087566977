import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { Link, Button } from "gatsby-theme-material-ui";
import Skeleton from "@material-ui/lab/Skeleton";

import Slider from "./slider";
import Nav from "./nav";
import Categories from "./categories";
import Quiz from "./quiz";
import Forecast from "./forecast";
import Box from "./box";
import Ad from "./ad";
import DashButtonDark from "../images/Dash_Button_Dark.png";
import DashButtonLight from "../images/Dash_Button_Light.png";
import quizIcon from "../images/Bulb_Icon.png";
import checkIcon from "../images/Checkmark.png";
import linkIcon from "../images/Open_URL.png";
import forecastIcon from "../images/Thermometer.png";

// Styles
const useStyles = makeStyles((theme) => ({
  organizer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    fontSize: "1.25rem",
    textAlign: "center",
  },
  titleContainer: {
    margin: "1rem",
    height: "2rem",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  menu: {
    color: theme.palette.secondary.main,
  },
  box: {
    display: "flex",
    alignItems: "center",
  },
  subtitle: {
    color: theme.palette.gray.main,
    textTransform: "capitalize",
    textAlign: "left",
  },
  subtitleContainer: {
    height: "3rem",
    width: theme.presets.width,
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
  card: {
    width: theme.presets.width,
    height: "3.25rem",
    margin: "1rem",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${DashButtonLight})`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  edgeCard: {
    width: theme.presets.width,
    height: "3.25rem",
    margin: "1rem",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${DashButtonDark})`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardText: {
    fontSize: "0.75rem",
    display: "flex",
    alignItems: "center",
  },
  city: {
    fontSize: "0.75rem",
  },
  icon: {
    height: "1.2rem",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  line: {
    margin: 0,
    width: theme.presets.width,
  },
  ad: {
    width: theme.presets.width,
  },
}));

export default function Dashboard(props) {
  const classes = useStyles();

  const [toggles, setToggles] = useState({
    showMain: true,
    showCat: false,
    showQuiz: false,
    showForecast: false,
  });

  const toggle = (key) => {
    setToggles({
      ...toggles,
      showMain: !toggles.showMain,
      [key]: !toggles[key],
    });
  };

  // From zipcode input
  const seasonAndYear = {
    season: props.season,
    year: props.year,
  };
  const weather = props?.weather;
  const currTemp = Math.round(weather?.current?.temp);
  const currWeather = weather?.current?.weather[0]?.description;
  const isAdsPresent = props?.ads?.length !== 0;

  const catClick = () => {
    toggle("showCat");
  };
  const quizClick = () => {
    toggle("showQuiz");
  };
  const forecastClick = () => {
    toggle("showForecast");
  };

  return (
    <>
      <Slider in={toggles.showMain} mount={"left"} unmount={"left"}>
        <div className={classes.organizer}>
          <Grid container className={classes.titleContainer}>
            <Grid item xs={2} className={classes.header}>
              <Nav
                links={[
                  {
                    text: "Homeclix.io Home",
                    link: "https://homeclix.io",
                  },
                  {
                    text: "Change Your Zip Code",
                    func: props.removeZip,
                  },
                ]}
              />
            </Grid>
            <Grid item xs={8} className={classes.header}>
              <Typography variant="h1" className={classes.title}>
                {seasonAndYear.season} Dashboard
              </Typography>
            </Grid>
          </Grid>
          <div className={classes.box}>
            <Box>
              <Typography variant="h1" className={classes.city}>
                {props.city + ", " + props.state}
              </Typography>
            </Box>
          </div>
          <div className={classes.subtitleContainer}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              {weather ? (
                currWeather + " " + currTemp + "\u00B0F"
              ) : (
                <Skeleton variant="text" width={"17rem"} />
              )}
            </Typography>
          </div>
          <hr className={classes.line} />
          <div className={classes.edgeCard} onClick={quizClick}>
            <Grid container spacing={1}>
              <Grid item xs={2} className={classes.iconContainer}>
                <img src={quizIcon} alt="quiz" className={classes.icon} />
              </Grid>
              <Grid item xs={10} className={classes.cardText}>
                <Typography variant="h1" className={classes.cardText}>
                  {seasonAndYear.season} Knowledge Quiz
                </Typography>
              </Grid>
            </Grid>
          </div>
          <hr className={classes.line} />
          <div className={classes.card} onClick={catClick}>
            <Grid container spacing={1}>
              <Grid item xs={2} className={classes.iconContainer}>
                <img src={checkIcon} alt="checklist" className={classes.icon} />
              </Grid>
              <Grid item xs={10} className={classes.cardText}>
                <Typography variant="h1" className={classes.cardText}>
                  {seasonAndYear.season} Checklist
                </Typography>
              </Grid>
            </Grid>
          </div>
          <hr className={classes.line} />
          <Link href={props.url} className={classes.card}>
            <Grid container spacing={1}>
              <Grid item xs={2} className={classes.iconContainer}>
                <img
                  src={linkIcon}
                  alt="city website"
                  className={classes.icon}
                />
              </Grid>
              <Grid item xs={10} className={classes.cardText}>
                <Typography variant="h1" className={classes.cardText}>
                  {props.city} Website
                </Typography>
              </Grid>
            </Grid>
          </Link>
          <hr className={classes.line} />
          <div className={classes.edgeCard} onClick={forecastClick}>
            <Grid container spacing={1}>
              <Grid item xs={2} className={classes.iconContainer}>
                <img
                  src={forecastIcon}
                  alt="forecast"
                  className={classes.icon}
                />
              </Grid>
              <Grid item xs={10} className={classes.cardText}>
                <Typography variant="h1" className={classes.cardText}>
                  5-Day Forecast
                </Typography>
              </Grid>
            </Grid>
          </div>
          {isAdsPresent && <Ad className={classes.ad} ads={props?.ads} />}
        </div>
      </Slider>
      <Slider in={toggles.showCat} mount={"right"} unmount={"right"}>
        <Categories
          time={seasonAndYear}
          listItems={props.listItems}
          daysTo={props.daysTo}
          setShowCat={catClick}
        />
      </Slider>
      <Slider in={toggles.showQuiz} mount={"right"} unmount={"right"}>
        <Quiz
          seasonAndYear={seasonAndYear}
          questions={props.questions}
          gradeScale={props.gradeScale}
          quizClick={quizClick}
        />
      </Slider>
      <Slider in={toggles.showForecast} mount={"right"} unmount={"right"}>
        <Forecast
          weather={weather}
          forecastClick={forecastClick}
          city={props.city}
          state={props.state}
        />
      </Slider>
    </>
  );
}
