import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography, Checkbox } from "@material-ui/core";
import { Link, Button } from "gatsby-theme-material-ui";
import OtpInput from "react-otp-input";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Amplify, { Auth, API, graphqlOperation } from "aws-amplify";

import Slider from "./slider";
import Terms from "./terms";
import logo from "../images/logo.png";
import loading from "../images/loading.svg";
import seasonCalc from "../utils/seasonCalc";
import { setLocation, setCity, setURL, removeLocation } from "../state/actions";
import Dashboard from "./dashboard";

// Queries
export const findClimate = `
  query FindClimate($zipcode: String!, $seasonEq: String!) {
    getZipcode(zipcode: $zipcode) {
      state {
        id
        ads {
          items {
            ad {
              title
              description
              url
              image {
                items {
                  thumbnail {
                    key
                  }
                }
              }
            }
          }
        }
      }
      city {
        name
        url
      }
      latitude
      longitude
    }
    lookupClimateZone(input:{zipcode: $zipcode}) {
      climateCategory {
        todos(filter:{todoSeason: {eq: $seasonEq}}) {
          items {
            todo {
              id
              title
              season
              description
              subcontractor
              category {
                name
                id
              }
              images {
                items {
                  createdAt
                  thumbnail {
                    key
                  }
                }
              }
            }
          }
        }
      }
      climate
      error
    }
    listQuestions(filter: {season: {eq: $seasonEq}}) {
      items {
        answers {
          answer
          answerExp
          correct
        }
        question
        image {
          items {
            fullsize {
              key
            }
            thumbnail {
              key
            }
          }
        }
      }
    }
    listGradeScales {
      items {
        id
        explanation
      }
    }
    listSeasonss {
      items {
        spring {
          month
          day
        }
        summer {
          month
          day
        }
        fall {
          month
          day
        }
        winter {
          month
          day
        }
      }
    }
  }
`;

export const getMissingZipCount = `
  query GetMissingZip($zipcode: ID!) {
    getMissingZip(id: $zipcode) {
      id
      count
    }
  }
`;

export const createMissingZip = `
  mutation CreateMissingZip(
    $input: CreateMissingZipInput!
    $condition: ModelMissingZipConditionInput
  ) {
    createMissingZip(input: $input, condition: $condition) {
      id
      count
    }
  }
`;

export const updateMissingZip = `
  mutation UpdateMissingZip(
    $input: UpdateMissingZipInput!
    $condition: ModelMissingZipConditionInput
  ) {
    updateMissingZip(input: $input, condition: $condition) {
      id
      count
    }
  }
`;

// Styles
const useStyles = makeStyles((theme) => ({
  otpInput: {
    height: "4rem",
    width: "2.25rem !important",
    fontSize: "1.25rem",
    margin: "0 0.2rem 0.1rem 0.2rem",
    border: "0px",
    backgroundColor: theme.palette.secondary.main,
    color: "white",
    borderRadius: "3px",
  },
  otpInputDark: {
    height: "5rem",
    width: "3rem !important",
    fontSize: "2rem",
    margin: "0 0.2rem 0.1rem 0.2rem",
    border: "0px",
    color: "white",
  },
  otpContainer: {
    margin: "0.3rem",
    justifyContent: "center",
    "& div": {
      margin: "0 0.3rem",
      borderBottom: "1px solid white",
      borderBottomColor: theme.palette.secondary.main,
      "& [aria-label='Please enter verification code. Digit 1'], & [aria-label='Digit 5']": {
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  },
  header: {
    textAlign: "center",
  },
  headerText: {
    textAlign: "center",
    fontSize: "1.25rem",
    margin: "3rem 0 3.5rem 0",
  },
  counter: {
    textAlign: "center",
    fontSize: "1.5rem",
    color: theme.palette.yellow.main,
  },
  subtitle: {
    fontSize: "0.7rem",
    margin: "0.7rem",
    textAlign: "center",
    color: theme.palette.gray.main,
  },
  logo: {
    marginTop: "1rem",
    height: "3rem",
  },
  error: {
    color: "red",
    textAlign: "center",
    fontSize: "0.7rem",
    margin: "0.7rem",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    height: "4.15rem",
    margin: "0.3rem",
  },
  termsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0 2.7rem",
  },
  terms: {
    width: theme.presets.width,
    display: "flex",
    alignItems: "center",
    margin: "0.5rem",
  },
  termsText: {
    textAlign: "center",
    fontSize: "0.7rem",
    color: theme.palette.gray.main,
  },
  link: {
    color: theme.palette.link.main,
    cursor: "pointer",
    textDecoration: "none",
  },
  button: {
    textTransform: "none",
    color: theme.palette.green.main,
  },
  checkbox: {
    color: theme.palette.secondary.main,
  },
  warningTitle: {
    textAlign: "center",
    margin: "6rem 0 0 0",
  },
  warning: {
    textAlign: "center",
  },
}));

// Redux store connectors
const mapState = (state) => {
  const { location } = state;
  return { location };
};
const mapDispatch = { setLocation, setCity, setURL, removeLocation };

// Main function
// Asks for zipcode, check for validity, and return the category page
function ZipcodeInput(props) {
  const classes = useStyles();
  const theme = useTheme();
  //Setup to filter out large screen sizes
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const urlMissingMsg =
    "We currently do not support this zip code. We have noted of your request and we will service this zip code very soon.";
  const invalidZip = "Invalid zip code";
  const [zip, setZip] = useState(props?.location?.zipcode || []);
  const [city, setCityName] = useState(props?.location?.city);
  const [url, setURL] = useState(props?.location?.url);
  const [stateID, setStateID] = useState(props?.location?.state);
  const [tasks, setTasks] = useState(null);
  const [error, setError] = useState(null);
  const [errorMsg, setErrorMsg] = useState(invalidZip);
  const [checked, setChecked] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [weather, setWeather] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [gradeScale, setGradeScale] = useState(null);
  const [terms, setTerms] = useState(false);
  const [ads, setAds] = useState(null);
  const [customSeasons, setCustomSeasons] = useState(null);
  const season = seasonCalc(customSeasons);

  const weatherData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          weatherAPI
        }
      }
    }
  `);
  const weatherAPI = weatherData.site.siteMetadata.weatherAPI;

  // Functions

  // On typing, will update the zipcode variable
  const handleChange = async (otp) => {
    setZip(otp);
  };

  // Handle checkbox functionality
  const onCheck = () => setChecked(!checked);

  // When the submit button is pressed, submit zipcode variable
  const submitForm = async () => {
    if (checked) {
      props.setLocation(zip);
      await fetchAllInfo(zip, season.currSeason);
    } else {
      setCheckError(true);
    }
  };

  useEffect(() => {
    const isLoadingFromPersist = props?.location?.zipcode && tasks === null;
    if (isLoadingFromPersist) {
      fetchAllInfo(zip, season.currSeason);
    }
  }, []);

  // Zipcode will be removed and app reset
  const removeZip = () => {
    setZip([]);
    setTasks(null);
    props.removeLocation();
    setCityName(null);
    setStateID(null);
  };

  // Takes a zipcode and tests for validity, then returns a climate zone (A, B, C, D, or E)
  const getClimate = async (zip, currSeason) => {
    const climate = await API.graphql({
      ...graphqlOperation(findClimate, { zipcode: zip, seasonEq: currSeason }),
      authMode: "API_KEY",
    });
    if (climate.data.getZipcode === null) {
      setErrorMsg(invalidZip);
      throw new Error("invalid zipcode");
    }
    return climate;
  };

  const incrementMissingURLCount = async (zip) => {
    const countPromise = await API.graphql({
      ...graphqlOperation(getMissingZipCount, { zipcode: zip }),
      authMode: "API_KEY",
    });
    const doesMissingZipExist = countPromise.data.getMissingZip !== null;
    if (!doesMissingZipExist) {
      const count = 1;
      const createCount = await API.graphql({
        ...graphqlOperation(createMissingZip, {
          input: { id: zip, count: count },
        }),
        authMode: "API_KEY",
      });
      return count;
    } else {
      const apiCount = countPromise.data.getMissingZip.count;
      const newCount = apiCount ? apiCount + 1 : 1;
      const updateCount = await API.graphql({
        ...graphqlOperation(updateMissingZip, {
          input: { id: zip, count: newCount },
        }),
        authMode: "API_KEY",
      });
      return count;
    }
  };

  const fetchWeather = async (lat, lon) => {
    const apiCall =
      "https://api.openweathermap.org/data/2.5/onecall?lat=" +
      lat +
      "&lon=" +
      lon +
      "&appid=" +
      weatherAPI +
      "&exclude=alerts,minutely,hourly&units=imperial";
    return await fetch(apiCall)
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          setError(error);
        }
      );
  };

  const getAndSetWeather = async (lat, lon) => {
    const newWeather = await fetchWeather(lat, lon);
    setWeather(newWeather);
  };

  const checkForURL = async (isCityServiced) => {
    if (!isCityServiced) {
      setErrorMsg(urlMissingMsg);
      const count = await incrementMissingURLCount(zip);
      throw ("URL missing for this zipcode: ", zip, count);
    }
  };

  const saveCityInfo = (city) => {
    setCityName(city?.name);
    props.setCity(city?.name);
    setURL(city?.url);
    props.setURL(city?.url);
  };

  const populateCityAndURL = (city) => {
    const cityPresent = city !== null;
    if (cityPresent) {
      // Save City info
      saveCityInfo(city);
    } else {
      // Clear City info
      saveCityInfo(null);
    }
  };

  // takes a zipcode and returns all associated tasks, ads, weather, and questions
  const fetchAllInfo = async (zip, currSeason) => {
    try {
      // fetch info for tasks, ads, and questions
      const climate = await getClimate(zip, currSeason);

      // Definitions
      const lat = climate.data.getZipcode.latitude;
      const lon = climate.data.getZipcode.longitude;
      const { city } = climate.data.getZipcode;
      const stateID = climate.data.getZipcode.state.id;
      const isCityServiced = !!city?.url;
      const climateZone = climate.data.lookupClimateZone.climateCategory;
      const newTasks = climateZone.todos.items;
      const newQuestions = climate.data.listQuestions.items;
      const newGradeScale = climate.data.listGradeScales.items;
      const newAds = climate.data.getZipcode.state.ads;
      const newCustomSeasons = climate.data.listSeasonss.items[0];

      // Check that city has an associated url
      await checkForURL(isCityServiced);

      // Populate city, state, tasks, questions, and ads for dashboard
      populateCityAndURL(city);
      setStateID(stateID);
      props.setLocation(zip, climateZone, stateID);

      // Set checklist tasks based on items in the given climate zone
      setTasks(newTasks);

      // Set the questions
      setQuestions(newQuestions);
      setGradeScale(newGradeScale);

      // Set Ads based on state
      setAds(newAds);

      // Set CustomSeasons if present
      setCustomSeasons(newCustomSeasons);

      // Get weather and set it with climate info
      await getAndSetWeather(lat, lon);

      // Reset checkbox and error for next entry
      setChecked(false);
      setError(null);
    } catch (e) {
      // In case of an error, clear all data
      console.log("error in fetchAllInfo: ", e);
      setError(e);
      removeZip();
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>HomeClix Homeowner Dashboard</title>
      </Helmet>
      {smallScreen ? (
        <>
          <Slider in={tasks !== null} mount={"right"} unmount={"right"}>
            <Dashboard
              listItems={tasks}
              city={city}
              url={url}
              state={stateID}
              zipcode={zip}
              season={season.currSeason}
              year={season.currYear}
              daysTo={season.daysTo}
              removeZip={removeZip}
              weather={weather}
              questions={questions}
              gradeScale={gradeScale}
              ads={ads?.items}
            />
          </Slider>
          <Slider in={tasks === null && !terms} mount={"left"} unmount={"left"}>
            <div>
              <div className={classes.header}>
                <img src={logo} alt="HomeClix" className={classes.logo} />
              </div>
              <Typography variant="h1" className={classes.headerText}>
                Homeowner Dashboard
              </Typography>
              {props?.location?.zipcode ? (
                <div className={classes.loading}>
                  <img src={loading} alt="loading" />
                </div>
              ) : (
                <OtpInput
                  value={zip}
                  onChange={handleChange}
                  numInputs={5}
                  separator={<span />}
                  isInputNum
                  inputStyle={classes.otpInput}
                  containerStyle={classes.otpContainer}
                  focusStyle={classes.otpFocus}
                  shouldAutoFocus
                />
              )}
              {error === null ? null : (
                <Typography className={classes.error}>{errorMsg}</Typography>
              )}
              <Typography variant="subtitle1" className={classes.subtitle}>
                Enter your zip code to see your custom dashboard
              </Typography>
              <div className={classes.termsContainer}>
                <div className={classes.terms}>
                  <Checkbox
                    className={classes.checkbox}
                    onChange={onCheck}
                    checked={checked}
                    size="small"
                  />
                  <Typography variant="subtitle1" className={classes.termsText}>
                    By checking this box you are agreeing to our terms and
                    conditions{" "}
                    <span
                      onClick={() => setTerms(true)}
                      className={classes.link}
                    >
                      here
                    </span>
                  </Typography>
                </div>
                {checked ? (
                  <Button
                    color="secondary"
                    className={classes.button}
                    onClick={submitForm}
                  >
                    <Typography variant="h3" className={classes.button}>
                      Submit
                    </Typography>
                  </Button>
                ) : null}
              </div>
            </div>
          </Slider>
          <Slider in={terms} mount={"right"} unmount={"right"}>
            <Terms termsClick={() => setTerms(false)} />
          </Slider>
        </>
      ) : (
        <div>
          <div className={classes.header}>
            <img src={logo} alt="HomeClix" className={classes.logo} />
          </div>
          <Typography variant="h1" className={classes.warningTitle}>
            We're sorry!
          </Typography>
          <Typography className={classes.warning}>
            HomeClix Homeowner Dashboard is supported on mobile displays only
          </Typography>
        </div>
      )}
    </div>
  );
}

// Connects to store
export default connect(mapState, mapDispatch)(ZipcodeInput);
