import React from "react";
import Slider from "react-slick";
import { S3Image } from "aws-amplify-react";
import { makeStyles } from "@material-ui/core/styles";

// Styles
const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
  },
  dotsContainer: {
    display: "flex !important",
    justifyContent: "center",
    width: "100%",
    padding: "0",
    margin: "0",
    listStyle: "none",
    textAlign: "center",
  },
  dots: {
    backgroundColor: "white",
    borderRadius: "2px",
    minWidth: "4px",
    minHeight: "4px",
    margin: "0.2rem",
  },
  activeDot: {
    backgroundColor: "grey",
    borderRadius: "2px",
    minWidth: "4px",
    minHeight: "4px",
    margin: "0.2rem",
  },
}));

// Builds the image carousel
const ImageCarousel = (props) => {
  const classes = useStyles();
  const compareFunc = (a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return (dateA > dateB) - (dateA < dateB);
  };
  const images = [...props.images];
  images.sort(compareFunc);
  var currImage = 0;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipe: true,
    arrows: false,
    focusOnSelect: true,
    adaptiveHeight: true,
    dotsClass: classes.dotsContainer,
    beforeChange: (oldIndex, newIndex) => {
      currImage = newIndex;
    },
    customPaging: (i) => (
      <>
        {currImage === i ? (
          <div className={classes.activeDot}></div>
        ) : (
          <div className={classes.dots}></div>
        )}
      </>
    ),
  };
  const Items = images.map((item) => (
    <div key={item.thumbnail.key}>
      <S3Image
        key={item.thumbnail.key}
        imgKey={`resized/${item.thumbnail.key.replace(/.+resized\//, "")}`}
        level="public"
        className={classes.image}
        style={{ display: "inline-block", paddingRight: "5px" }}
      />
    </div>
  ));
  return <Slider {...settings}>{Items}</Slider>;
};

export default ImageCarousel;
