import React from "react";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import Amplify, { API, graphqlOperation } from "@aws-amplify/api";
import { S3Image } from "aws-amplify-react";
import { Button } from "gatsby-theme-material-ui";
import { connect } from "react-redux";

import { setQuizComplete, removeQuizComplete } from "../state/actions";
import Slider from "./slider";
import Header from "./header";
import Box from "./box";
import CompletionBar from "./completionBar";
import AnswerButton from "../images/Quiz_Answers_Button.png";
import Gold from "../images/Trophy1.png";
import Silver from "../images/Trophy2.png";
import Bronze from "../images/Trophy3.png";
import Fail from "../images/Trophy4.png";

// Styles
const useStyles = makeStyles((theme) => ({
  completionBar: {
    width: theme.presets.width,
  },
  infoContainer: {
    top: "4rem",
    maxWidth: "100%",
  },
  questionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  image: {
    width: theme.presets.width,
    height: "180px",
    objectFit: "cover",
    margin: "1rem auto",
    display: "flex",
    justifyContent: "center",
  },
  answerContainer: {
    justifyContent: "space-around",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  answer: {
    fontSize: "0.75rem",
    textAlign: "center",
    padding: "1rem",
  },
  rightAnswer: {
    color: theme.palette.green.main,
  },
  wrongAnswer: {
    color: theme.palette.red.main,
  },
  answerExp: {
    fontSize: "0.75rem",
    textAlign: "left",
    color: theme.palette.primary.main,
    margin: "1rem 0",
  },
  itemText: {
    textAlign: "left",
    color: theme.palette.gray.main,
    padding: "1rem 0",
  },
  leftText: {
    width: theme.presets.width,
  },
  introContainer: {
    top: "4rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  outroContainer: {
    width: theme.presets.width,
    marginBottom: "1rem",
    height: "234px",
    border: "3px solid #FFFFFF",
    borderRadius: "3px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  box: {
    border: "3px solid #FFFFFF",
    borderRadius: "3px",
    width: theme.presets.width,
    margin: "1rem",
    height: "180px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  boxText: {
    textAlign: "center",
    padding: "1rem",
    fontSize: "0.75rem",
  },
  startButton: {
    color: theme.palette.green.main,
    textTransform: "none",
    margin: "0.5rem",
  },
  nextButton: {
    color: theme.palette.green.main,
    textTransform: "none",
    margin: "2.5rem",
  },
  retakeButton: {
    color: theme.palette.green.main,
    textTransform: "none",
  },
  card: {
    margin: "0 0 1rem 0",
  },
  grade: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  badge: {
    height: "75px",
    width: "150px",
    margin: "0 -38px",
  },
  subtitle: {
    color: theme.palette.gray.main,
    textTransform: "capitalize",
    textAlign: "left",
  },
  subtitleContainer: {
    height: "3rem",
    width: theme.presets.width,
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
  line: {
    width: theme.presets.width,
    margin: 0,
  },
}));

const mapState = (state) => {
  const { quizCompleted } = state;
  return quizCompleted;
};
const mapDispatch = { setQuizComplete, removeQuizComplete };

function Quiz(props) {
  const d = new Date();
  const classes = useStyles();
  const season = props.seasonAndYear.season;
  const currYear =
    d.getMonth() === 11
      ? props.seasonAndYear.year + 1
      : props.seasonAndYear.year;
  const questions = props.questions;
  const [showIntro, setShowIntro] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [mountQuestion, setMountQuestion] = useState("right");
  const [showOutro, setShowOutro] = useState(false);
  const [answered, setAnswered] = useState(false);
  const [correct, setCorrect] = useState(null);
  const [rightAns, setRightAns] = useState(null);
  const [wrongAns, setWrongAns] = useState(null);
  const [currQuestion, setCurrQuestion] = useState(0);
  const [percentComplete, setPercentComplete] = useState("0%");
  const [numCorrect, setNumCorrect] = useState(
    props?.grade ? Math.ceil((props?.grade / 100) * questions.length) : 0
  );
  const [gradePerc, setGradePerc] = useState(props?.grade);
  const timestamp = props?.time;
  const gradeExplanations = props.gradeScale;
  const rand = Math.floor(Math.random() * 10) % 3;

  const gradeExp = (grade) => {
    const options = gradeExplanations.filter((item) => item.id === grade);
    return options[0].explanation[rand];
  };

  useEffect(() => {
    timeChecker();
  }, []);

  const timeChecker = () => {
    // checks that the completed date is not more than 3 months ago
    if (timestamp?.season !== season || timestamp?.year !== currYear) {
      props.removeQuizComplete();
    }
    if (timestamp?.year) {
      setShowIntro(false);
      setShowOutro(true);
    } else {
      setShowIntro(true);
    }
  };

  // calculate the grade
  const calcPercentComplete = () => {
    const dec = (currQuestion + 1) / questions.length;
    const perc = Math.floor(dec * 100) + "%";
    setPercentComplete(perc);
  };

  // Find which of two answers is labeled as correct
  const calcRightAns = (answers) => {
    const rightAnsInd = answers.filter((ans) => ans.correct);
    const corrInd = answers.findIndex((item) => item === rightAnsInd[0]);
    setRightAns(corrInd);
    // Set the wrong answer to the other option
    setWrongAns((corrInd + 1) % 2);
  };

  // Logic when answering a question
  const answer = (ans) => {
    setAnswered(true);
    if (ans === rightAns) {
      setCorrect(true);
      setNumCorrect(numCorrect + 1);
    } else {
      setCorrect(false);
    }
  };

  // buttons to choose an answer
  const answer0 = () => answer(0);
  const answer1 = () => answer(1);

  // Move to the next question or outro slide
  const nextQuestion = () => {
    const nextQuestion = currQuestion + 1;
    if (questions[nextQuestion]) {
      setShowQuestion(false);
      calcRightAns(questions[nextQuestion].answers);
      setTimeout(() => {
        setCurrQuestion(nextQuestion);
        calcPercentComplete();
        setAnswered(false);
        setShowQuestion(true);
      }, 100);
    } else {
      setShowQuestion(false);
      countFinalGrade();
      setShowOutro(true);
    }
  };

  // Move from intro slide to questions
  const startQuiz = () => {
    props.removeQuizComplete();
    setCurrQuestion(0);
    calcRightAns(questions[currQuestion].answers);
    setShowIntro(false);
    setShowQuestion(true);
  };

  // Reset Quiz
  const retakeQuiz = async () => {
    setMountQuestion("left");
    setShowOutro(false);
    setCurrQuestion(0);
    calcRightAns(questions[currQuestion].answers);
    setPercentComplete("0%");
    setAnswered(false);
    setShowQuestion(true);
  };

  const afterResetQuiz = () => {
    setMountQuestion("right");
    props.removeQuizComplete();
    setNumCorrect(0);
    setGradePerc(0);
  };

  const countFinalGrade = () => {
    const finalGrade = Math.floor((numCorrect * 100) / questions.length);
    setGradePerc(finalGrade);
    props.setQuizComplete(finalGrade, season, currYear);
  };

  // Quiz mechanics
  // The options for a question
  const Answers = (props) => {
    if (!answered) {
      // Show questions
      return (
        <div className={classes.answerContainer}>
          <div onClick={answer0} className={classes.card}>
            <Box>
              <Typography className={classes.answer}>
                {props.answers[0]?.answer}
              </Typography>
            </Box>
          </div>
          <div onClick={answer1} className={classes.card}>
            <Box>
              <Typography className={classes.answer}>
                {props.answers[1]?.answer}
              </Typography>
            </Box>
          </div>
        </div>
      );
    } else if (correct) {
      // Positive Result
      return (
        <div className={classes.leftText}>
          <Typography className={classes.answerExp}>
            <span className={classes.rightAnswer}>Correct!</span>{" "}
            {props?.answers[rightAns]?.answerExp}
          </Typography>
        </div>
      );
    } else {
      // Negative Result
      return (
        <div className={classes.leftText}>
          <Typography className={classes.answerExp}>
            <span className={classes.wrongAnswer}>Wrong!</span>{" "}
            {props?.answers[wrongAns]?.answerExp}
          </Typography>
        </div>
      );
    }
  };
  // Builds a single question
  const Question = (props) => {
    return (
      <div className={classes.infoContainer}>
        <div className={classes.imageContainer}>
          {props?.question?.image?.items[0]?.thumbnail?.key ? (
            <S3Image
              key={props.question.image.items[0].thumbnail.key}
              imgKey={`resized/${props.question.image.items[0].thumbnail.key.replace(
                /.+resized\//,
                ""
              )}`}
              level="public"
              className={classes.image}
            />
          ) : (
            <>
              {props?.question?.image?.items[0]?.fullsize?.key ? (
                <S3Image
                  key={props.question.image.items[0].fullsize.key}
                  imgKey={`resized/${props.question.image.items[0].fullsize.key.replace(
                    /.+resized\//,
                    ""
                  )}`}
                  level="public"
                  className={classes.image}
                />
              ) : (
                <div className={classes.box}></div>
              )}
            </>
          )}
          <div className={classes.completionBar}>
            <CompletionBar width={percentComplete} />
          </div>
        </div>
        <div className={classes.questionContainer}>
          {answered ? null : (
            <div className={classes.leftText}>
              <Typography variant="subtitle1" className={classes.itemText}>
                {props.question.question}
              </Typography>
            </div>
          )}
          <Answers answers={props.question.answers} />
          {answered ? (
            <Button onClick={nextQuestion}>
              <Typography variant="h3" className={classes.nextButton}>
                Next Question
              </Typography>
            </Button>
          ) : null}
        </div>
      </div>
    );
  };

  // Intro slide
  const Intro = () => (
    <div className={classes.introContainer}>
      <Box>
        <Typography className={classes.boxText}>
          {questions?.length || 0} Question Graded Quiz
        </Typography>
      </Box>
      <div className={classes.subtitleContainer}>
        <Typography variant="subtitle1" className={classes.subtitle}>
          Click to Start
        </Typography>
      </div>
      <hr className={classes.line} />
      <Button onClick={startQuiz}>
        <Typography variant="h3" className={classes.startButton}>
          Start Quiz!
        </Typography>
      </Button>
    </div>
  );

  // Outro slide
  const Outro = (props) => {
    const gradeScale = [80, 60, 30];
    const trophy = (() => {
      if (gradePerc > gradeScale[0]) return [Gold, "Gold", "A"];
      else if (gradePerc > gradeScale[1]) return [Silver, "Silver", "B"];
      else if (gradePerc > gradeScale[2]) return [Bronze, "Bronze", "C"];
      else return [Fail, "...", "F"];
    })();
    return (
      <div className={classes.introContainer}>
        <div className={classes.outroContainer}>
          <Typography className={classes.boxText}>
            You got {numCorrect}/{questions.length} correct
          </Typography>
          <div className={classes.grade}>
            <img src={trophy[0]} alt="trophy" className={classes.badge} />
            <Typography className={classes.boxText}>
              You got {trophy[1]}
            </Typography>
          </div>
          <Typography className={classes.boxText}>
            {gradeExp(trophy[2])}
          </Typography>
        </div>
        <Button onClick={retakeQuiz}>
          <Typography variant="h3" className={classes.retakeButton}>
            Retake Quiz
          </Typography>
        </Button>
      </div>
    );
  };

  return (
    <div>
      <Header back={props.quizClick}>{season} Quiz</Header>
      <div classes={classes.infoContainer}>
        <Slider in={showQuestion} mount={mountQuestion} unmount={"left"}>
          <Question
            question={questions[currQuestion]}
            className={classes.infoContainer}
          />
        </Slider>
        <Slider
          in={showOutro}
          mount={"right"}
          unmount={"right"}
          afterUnmount={afterResetQuiz}
        >
          <Outro className={classes.infoContainer} />
        </Slider>
        <Slider in={showIntro} mount={"right"} unmount={"left"}>
          <Intro className={classes.infoContainer} />
        </Slider>
      </div>
    </div>
  );
}

export default connect(mapState, mapDispatch)(Quiz);
