import React from "react";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

import Slider from "./slider";
import Card from "./card";
import CheckList from "./checkList";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    top: "164px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    top: "0px",
    width: "100%",
    justifyContent: "center",
  },
  cards: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  button: {
    display: "flex",
    justifyContent: "left",
    margin: "0.5rem",
  },
  menu: {
    color: theme.palette.secondary.main,
    margin: "0 1rem",
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  line: {
    width: theme.presets.width,
    margin: 0,
  },
}));

export default function Cards(props) {
  const classes = useStyles();
  const [showCat, setShowCat] = useState(false);
  const [listItems, setListItems] = useState({});

  // Which cards should be yellow instead of orange
  const edgeCards = new Set([0, props.items.length - 1]);

  const toggleCards = () => {
    setShowCat(!showCat);
    props.switchList();
  };
  const handleClick = (item) => {
    setListItems(item);
    toggleCards();
  };

  const list = (
    <div className={classes.container}>
      <CheckList listItems={listItems} showCards={toggleCards} />
    </div>
  );
  // Create a card for each category to display on mobile
  const cards = props.items.map((item, i) => {
    let cardPos = "mid";
    if (edgeCards.has(i)) cardPos = "edge";
    i++;
    return (
      <>
        <hr className={classes.line} key={item + "line"} />
        <Card
          listItems={props.categories[item]}
          handleClick={handleClick}
          key={item}
          cardPos={cardPos}
        />
      </>
    );
  });

  return (
    <div className={classes.container}>
      <div className={classes.cards}>
        <Slider in={showCat} mount={"right"} unmount={"right"}>
          {list}
        </Slider>
        <Slider in={!showCat} mount={"left"} unmount={"left"}>
          <div className={classes.cardContainer}>{cards}</div>
        </Slider>
      </div>
    </div>
  );
}
