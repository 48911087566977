import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { CSSTransition } from "react-transition-group";

const useStyles = makeStyles((theme) => ({
  leftEnter: {
    position: "absolute",
    top: "0px",
    left: "0px",
    transform: "translate(-999px, 0px)",
  },
  leftEnterActive: {
    position: "absolute",
    left: "0px",
    transform: "translate(0px, 0px)",
    transition: "transform 500ms",
    width: "100%",
  },
  leftExitActive: {
    position: "absolute",
    left: "0px",
    transform: "translate(-999px, 0px)",
    transition: "transform 500ms",
  },
  rightEnter: {
    position: "absolute",
    left: "0px",
    transform: "translate(999px, 0px)",
  },
  rightEnterActive: {
    position: "absolute",
    left: "0px",
    transform: "translate(0px, 0px)",
    transition: "transform 500ms",
    width: "100%",
  },
  rightExitActive: {
    position: "absolute",
    width: "100%",
    left: "0px",
    transform: "translate(999px, 0px)",
    transition: "transform 500ms",
  },
  rightExitEnd: {
    width: "0%",
    height: "0%",
  },
}));

export default function Slider(props) {
  const classes = useStyles();
  const mountRightUnmountRight = {
    enter: classes.rightEnter,
    enterActive: classes.rightEnterActive,
    exitActive: classes.rightExitActive,
    exitEnd: classes.rightExitEnd,
  };
  const mountRightUnmountLeft = {
    enter: classes.rightEnter,
    enterActive: classes.rightEnterActive,
    exitActive: classes.leftExitActive,
  };
  const mountLeftUnmountLeft = {
    enter: classes.leftEnter,
    enterActive: classes.leftEnterActive,
    exitActive: classes.leftExitActive,
  };
  const mountLeftUnmountRight = {
    enter: classes.leftEnter,
    enterActive: classes.leftEnterActive,
    exitActive: classes.rightExitActive,
  };
  const transitionClasses = (() => {
    if (props.mount === "right") {
      if (props.unmount === "right") {
        return mountRightUnmountRight;
      } else {
        return mountRightUnmountLeft;
      }
    } else {
      if (props.unmount === "right") {
        return mountLeftUnmountRight;
      } else {
        return mountLeftUnmountLeft;
      }
    }
  })();

  return (
    <CSSTransition
      in={props.in}
      timeout={500}
      classNames={transitionClasses}
      mountOnEnter
      unmountOnExit
      onExited={props.afterUnmount}
    >
      {props.children}
    </CSSTransition>
  );
}
