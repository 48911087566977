import React from "react";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";

import Header from "./header";
import Box from "./box";
import DailyWeather from "./dailyWeather";

// Styles
const useStyles = makeStyles((theme) => ({
  infoContainer: {
    maxWidth: "75%",
  },
  line: {
    width: theme.presets.width,
    background: "white",
    height: "1px",
    border: "none",
  },
  weather: {
    color: theme.palette.gray.main,
    textTransform: "capitalize",
    textAlign: "left",
  },
  weatherContainer: {
    height: "3rem",
    width: theme.presets.width,
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
  city: {
    fontSize: "0.75rem",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

export default function Forecast(props) {
  const classes = useStyles();
  const weather = props?.weather;
  const dailyWeather = weather?.daily;
  const currTemp = Math.round(weather?.current?.temp);
  const currWeather = weather?.current?.weather[0]?.description;
  // Builds the forecast container
  return (
    <div className={classes.container}>
      <Header back={props.forecastClick}>5-Day Forecast</Header>
      <Box>
        <Typography variant="h1" className={classes.city}>
          {props.city + ", " + props.state}
        </Typography>
      </Box>
      <div className={classes.weatherContainer}>
        <Typography variant="subtitle1" className={classes.weather}>
          {weather ? (
            currWeather + " " + currTemp + "\u00B0F"
          ) : (
            <Skeleton variant="text" width={"17rem"} />
          )}
        </Typography>
      </div>
      {weather ? (
        <>
          <hr className={classes.line} />
          <div className={classes.infoContainer}>
            {dailyWeather?.map((day, i) => (
              <DailyWeather day={day} iter={i} />
            ))}
          </div>
        </>
      ) : (
        <Skeleton variant="box" width={270} height={130} />
      )}
    </div>
  );
}
