import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { S3Image } from "aws-amplify-react";

const useStyles = makeStyles((theme) => ({
  img: {
    width: "100%",
  },
  text: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    margin: "-0.5rem 0 0.5rem 0",
  },
  headerText: {
    lineHeight: "1rem",
  },
  title: {
    fontWeight: 700,
  },
  description: {
    lineHeight: "1rem",
  },
  link: {
    textDecoration: "none !important",
    color: "white",
  },
}));

// requires a title and description, takes an optional image
export default function Ad(props) {
  const classes = useStyles();
  const ads = props?.ads;
  const ad = (() => {
    const adNum = Math.floor(Math.random() % ads?.length);
    return ads ? ads[adNum].ad : null;
  })();
  const img = ad?.image?.items[0]?.thumbnail?.key;

  return (
    <div className={props.className}>
      <a href={ad?.url} className={classes.link}>
        <div className={classes.header}>
          <Typography variant="subtitle1" className={classes.headerText}>
            Sponsored by:
          </Typography>
        </div>
        <Grid container spacing={3}>
          {img && (
            <Grid item xs={5}>
              <S3Image
                key={img}
                imgKey={`resized/${img.replace(/.+resized\//, "")}`}
                level="public"
                className={classes.img}
                alt="Ad Image"
              />
            </Grid>
          )}
          <Grid item xs={7}>
            <div className={classes.text}>
              <Typography variant="subtitle1" className={classes.title}>
                {ad?.title}
              </Typography>
              <Typography variant="subtitle1" className={classes.description}>
                {ad?.description}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </a>
    </div>
  );
}
