import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  box: {
    width: theme.presets.width,
    height: "3.25rem",
    border: "3px solid #FFFFFF",
    borderRadius: "3px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function Box({ children }) {
  const classes = useStyles();
  return <div className={classes.box}>{children}</div>;
}
