import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

import CheckItem from "./checkItem";
import seasonCalc from "../utils/seasonCalc";
import backButton from "../images/Back.png";

// Style
const useStyles = makeStyles((theme) => ({
  ul: {
    listStyleType: "none",
    paddingLeft: "0px",
    margin: "0 2rem",
  },
  listTitle: {
    textAlign: "center",
    fontSize: "1.5rem",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  menu: {
    color: theme.palette.secondary.main,
  },
  titleContainer: {
    margin: "1rem 0",
    height: "2rem",
  },
  listContainer: {
    margin: "1rem",
  },
  season: {
    fontSize: "0.8rem",
    color: theme.palette.gray.main,
  },
  back: {
    width: "2.125rem",
  },
}));

// Main function
// Takes a json list of tasks, adds a title, and makes a list of CheckItems
export default function CheckList(props) {
  const seasons = seasonCalc();
  const classes = useStyles();
  const items = props.listItems;
  const theme = useTheme();

  const showCards = () => props.showCards();

  // Makes a list of CheckItems
  const listItems = items.map((item) => (
    <li key={item.todo.id}>
      <CheckItem
        thingToDo={item.todo.title}
        description={item.todo.description}
        subcontractor={item.todo.subcontractor}
        images={item.todo.images}
        key={item.todo.id}
        id={item.todo.id}
      />
    </li>
  ));

  return (
    <div>
      <div className={classes.titleContainer}>
        <Grid container>
          <Grid item onClick={showCards} xs={2} className={classes.header}>
            <img src={backButton} className={classes.back} alt="back" />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={6} className={classes.header}>
            <Typography variant="h2" className={classes.listTitle}>
              {items[0]?.todo?.category?.name || ""}
            </Typography>
          </Grid>
          <Grid item xs={3} className={classes.header}>
            <Typography className={classes.season}>
              {seasons.currSeason} {seasons.currYear}
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className={classes.listContainer}>
        <ul className={classes.ul}>{listItems}</ul>
      </div>
    </div>
  );
}
