import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import backButton from "../images/Back.png";

// Styles
const useStyles = makeStyles((theme) => ({
  titleContainer: {
    margin: "1rem 0",
    height: "2rem",
    width: "100%",
  },
  title: {
    fontSize: "1.25rem",
    textAlign: "center",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  back: {
    width: "2.125rem",
  },
}));

export default function Header(props) {
  const classes = useStyles();
  return (
    <div className={classes.titleContainer}>
      <Grid container>
        <Grid item onClick={props.back} xs={2} className={classes.header}>
          <img src={backButton} className={classes.back} alt="back" />
        </Grid>
        <Grid item xs={8} className={classes.header}>
          <Typography variant="h1" className={classes.title}>
            {props.children}
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.header}></Grid>
      </Grid>
    </div>
  );
}
