const seasonStartDates = {
  spring: {
    month: 2,
    day: 1,
  },
  summer: {
    month: 5,
    day: 1,
  },
  fall: {
    month: 8,
    day: 1,
  },
  winter: {
    month: 11,
    day: 1,
  },
};

export default function SeasonCalc(usrDates) {
  const startDates = usrDates ? usrDates : seasonStartDates;
  const currYear = new Date().getFullYear();
  const seasons = [
    {
      start: new Date( // first of winter last year
        currYear - 1,
        startDates.winter.month,
        startDates.winter.day
      ),
      season: "Winter",
    },
    {
      start: new Date(currYear, startDates.spring.month, startDates.spring.day),
      season: "Spring",
    },
    {
      start: new Date(currYear, startDates.summer.month, startDates.summer.day),
      season: "Summer",
    },
    {
      start: new Date(currYear, startDates.fall.month, startDates.fall.day),
      season: "Fall",
    },
    {
      start: new Date(currYear, startDates.winter.month, startDates.winter.day),
      season: "Winter",
    },
    {
      start: new Date( // first of spring next year
        currYear + 1,
        startDates.spring.month,
        startDates.spring.day
      ),
      season: "Spring",
    },
  ];
  const now = new Date();
  const findDiffFromSeasonStart = (seasons) => {
    return seasons.map((item) =>
      Math.floor((item.start - now) / 1000 / 60 / 60 / 24)
    );
  };
  const result = findDiffFromSeasonStart(seasons);

  const setCurrSeasonInd = () => {
    const possibleSeason = Math.max(...result.filter((item) => item < 0));
    const firstGuess = result.findIndex((item) => item === possibleSeason);
    const secondGuess = firstGuess + 1;
    if (firstGuess === -1) 0;
    return firstGuess;
  };
  const currSeasonInd = setCurrSeasonInd();
  const daysTo = result[currSeasonInd + 1] + 1;
  const currSeason = seasons[currSeasonInd].season;

  return { daysTo, currSeason, currYear };
}
