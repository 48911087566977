import React from "react";
import { useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Button from "@material-ui/core/Button";
import MenuIcon from "../images/Menu_Icon.png";

const useStyles = makeStyles((theme) => ({
  menu: {
    width: "1.5rem",
    height: "1.5rem",
  },
}));

export default function Nav(props) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (state) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(state);
  };

  const listItems = props.links.map((item) => (
    <ListItem
      button
      component="a"
      onClick={item.func}
      href={item.link}
      key={item.text}
    >
      <ListItemIcon>
        {item.icon ? (
          <img src={item.icon} alt="Nav Button" className={classes.menu} />
        ) : (
          <img src={MenuIcon} alt="Nav Button" className={classes.menu} />
        )}
      </ListItemIcon>
      <ListItemText primary={item.text} />
    </ListItem>
  ));

  return (
    <Button onClick={toggleDrawer(!open)}>
      <img src={MenuIcon} alt="Open Nav" className={classes.menu} />
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <div
          className={classes.list}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>{listItems}</List>
        </div>
      </Drawer>
    </Button>
  );
}
