import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

// Styles
const useStyles = makeStyles((theme) => ({
  counter: {
    color: (props) => props.color,
  },
}));

export default function Counter(props) {
  const season = props.time.season;
  const year = props.time.year;
  const daysTo = props.daysTo;
  const countdown = daysTo + " Days Remaining in " + season + " " + year;

  const colorPick = (season) => {
    const theme = useTheme();
    switch (season) {
      case "Winter":
        return theme.palette.iceBlue.main;
      case "Spring":
        return theme.palette.green.main;
      case "Summer":
        return theme.palette.yellow.main;
      case "Fall":
        return theme.palette.orange.main;
      default:
        return "error";
    }
  };

  const color = colorPick(season);
  const classes = useStyles({ color: color });

  return (
    <div>
      <Typography variant="subtitle1" className={classes.counter}>
        {countdown}
      </Typography>
    </div>
  );
}
