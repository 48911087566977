import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

import DashButtonDark from "../images/Dash_Button_Dark.png";
import DashButtonLight from "../images/Dash_Button_Light.png";
import InteriorIcon from "../images/Indoor.png";
import LifeIcon from "../images/Life.png";
import ExteriorIcon from "../images/Exterior.png";
import LandscapingIcon from "../images/Landscaping.png";

const useStyles = makeStyles((theme) => ({
  card: {
    width: theme.presets.width,
    height: "3.25rem",
    margin: "1rem",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: (props) => `url(${props.cardBG})`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cardText: {
    fontSize: "0.75rem",
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    height: "1.2rem",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default function Card(props) {
  const listItems = props?.listItems;
  const categoryName = listItems[0]?.todo.category.name;
  const handleClick = () => props?.handleClick(listItems);
  var cardBG = DashButtonDark;
  if (props?.cardPos === "mid") {
    cardBG = DashButtonLight;
  }
  const classes = useStyles({ cardBG: cardBG });
  const icon = (() => {
    switch (categoryName) {
      case "Interior":
        return InteriorIcon;
        break;
      case "Life":
        return LifeIcon;
        break;
      case "Exterior":
        return ExteriorIcon;
        break;
      case "Landscaping":
        return LandscapingIcon;
        break;
      default:
        return null;
    }
  })();

  return (
    <div className={classes.card} onClick={handleClick}>
      <Grid container spacing={1}>
        <Grid item xs={2} className={classes.iconContainer}>
          <img src={icon} alt={categoryName} className={classes.icon} />
        </Grid>
        <Grid item xs={10} className={classes.textContainer}>
          <Typography variant="h1" className={classes.cardText}>
            {categoryName}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
