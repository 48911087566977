import React from "react";

import ZipcodeInput from "../components/zipcodeInput"


const IndexPage = () => {
  return (
    <ZipcodeInput />
  );
}

export default IndexPage

