import React from "react";
import { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { Button } from "gatsby-theme-material-ui";

import Slider from "./slider";
import Cards from "./cards";
import Counter from "./counter";
import Header from "./header";
import Box from "./box";

// Styles
const useStyles = makeStyles((theme) => ({
  organizer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  menu: {
    color: theme.palette.secondary.main,
  },
  headerButton: {
    margin: "0.5rem",
    cursor: "pointer",
    textAlign: "center",
  },
  subtitle: {
    color: theme.palette.gray.main,
  },
  subtitleContainer: {
    height: "3rem",
    width: theme.presets.width,
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
  },
}));

// Main function
// takes a list of tasks, sorts them by category, and returns a checklist for each category
export default function Categories(props) {
  const classes = useStyles();
  const input = props.listItems;
  const theme = useTheme();
  const [showList, setShowList] = useState(false);

  // Sorts a list of tasks by category
  const categories = input.reduce((acc, item) => {
    const categoryName = item?.todo?.category?.name;
    if (acc[categoryName] === undefined) acc[categoryName] = [];
    acc[categoryName].push(item);
    return acc;
  }, {});

  const items = Object.keys(categories);

  // for use on small screens
  const switchList = () => setShowList(!showList);

  return (
    <div className={classes.organizer}>
      <Slider in={!showList} mount={"left"} unmount={"left"}>
        <div className={classes.organizer}>
          <Header back={props.setShowCat}>{props.time.season} Checklist</Header>
          <Box>
            <Counter time={props.time} daysTo={props.daysTo} />
          </Box>
          <div className={classes.subtitleContainer}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              Select a Category
            </Typography>
          </div>
        </div>
      </Slider>
      <Cards items={items} categories={categories} switchList={switchList} />
    </div>
  );
}
