import React from "react";
import { useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import RemoveRoundedIcon from "@material-ui/icons/RemoveRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import { connect } from "react-redux";

import ImageCarousel from "./imageCarousel";
import { setCompleted, removeCompleted } from "../state/actions";
import moneyIcon from "../images/Dollar_Sign.png";

import "jquery";
import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Styles
const useStyles = makeStyles((theme) => ({
  checkitem: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "start",
    cursor: "pointer",
  },
  checkitemTitle: {
    alignItems: "center",
    justifyContent: "space-between",
    width: "-webkit-fill-available",
    display: "flex",
  },
  infoContainer: {
    maxWidth: theme.presets.width,
    margin: "auto",
  },
  strikethrough: {
    textDecoration: "line-through",
    display: "inline",
  },
  checkbox: {
    color: theme.palette.secondary.main,
  },
  expander: {
    color: theme.palette.secondary.main,
    fontSize: "2.5rem",
  },
  contractor: {
    color: theme.palette.orange.main,
    fontSize: "2.5rem",
  },
  line: {
    width: theme.presets.width,
    border: "0px",
    height: "1px",
    backgroundColor: "white",
  },
  icon: {
    height: "1rem",
    marginRight: "0.5rem",
  },
  subcontractorContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
  },
}));

const areEqual = (prevProps, nextProps) => {
  if (prevProps[prevProps.id] === nextProps[nextProps.id]) {
    return true;
  } else {
    return false;
  }
};

// Redux store connectors
const mapState = (state) => {
  const { todosCompleted } = state;
  return todosCompleted;
};
const mapDispatch = { setCompleted, removeCompleted };

// Main function
// Takes a json object and returns a single item of a checklist
// Every item will have a check, name, and description.
function CheckItem(props) {
  const classes = useStyles();
  const todoID = props.id;
  const timestamp = props[todoID];
  let checked;
  const [showContainer, setShowContainer] = useState(false);
  const onClick = () => setShowContainer(!showContainer);
  // toggles the checkbox and removes or stores a timestamp in the store
  const onCheck = () => {
    checked
      ? props.removeCompleted(todoID)
      : props.setCompleted(todoID, Date.now());
    setShowContainer(false);
  };

  // checks that the completed date is not more than 3 months ago
  if (timestamp < Date.now() - 3 * 30 * 24 * 60 * 60 * 1000) {
    props.removeCompleted(todoID);
  }

  // The checkbox status is tied to the timestamp in the store
  {
    timestamp ? (checked = true) : (checked = false);
  }

  // Builds the description container
  const Container = () => (
    <div className={classes.infoContainer}>
      {props.images ? <ImageCarousel images={props.images.items} /> : null}
      <Typography variant="subtitle1">{props.description}</Typography>
      {props.subcontractor ? (
        <div className={classes.subcontractorContainer}>
          <img src={moneyIcon} alt="contractor icon" className={classes.icon} />
          <div>
            <Typography variant="subtitle1">
              Hire a Pro - {props.subcontractor}
            </Typography>
          </div>
        </div>
      ) : null}
      <hr className={classes.line}></hr>
    </div>
  );

  // Return the CheckItem
  return (
    <div key={props.id}>
      <div className={classes.checkitem}>
        <Checkbox
          onChange={onCheck}
          checked={checked}
          name={props.id}
          inputProps={{ "aria-label": "uncontrolled-checkbox" }}
          className={classes.checkbox}
        />
        <div className={classes.checkitemTitle} onClick={onClick}>
          <Typography
            variant="subtitle1"
            className={checked && classes.strikethrough}
          >
            {props.thingToDo}
          </Typography>
          {showContainer ? (
            <RemoveRoundedIcon className={classes.contractor} />
          ) : (
            <AddRoundedIcon className={classes.expander} />
          )}
        </div>
      </div>
      {showContainer ? <Container /> : null}
    </div>
  );
}

// Connects to the store
export default connect(mapState, mapDispatch)(React.memo(CheckItem, areEqual));
