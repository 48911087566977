import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useStaticQuery, graphql } from "gatsby";
import Header from "./header";
import Box from "./box";

// Styles
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    width: theme.presets.width,
    margin: "1rem",
    fontSize: "0.75rem",
  },
  boldText: {
    width: theme.presets.width,
    margin: "1rem",
    fontSize: "0.75rem",
    fontWeight: "700",
  },
  boxText: {
    fontSize: "0.75rem",
  },
}));

export default function Terms(props) {
  const classes = useStyles();
  const contentQuery = useStaticQuery(graphql`
    query ContentQuery {
      site {
        siteMetadata {
          terms {
            updateDate
            content {
              type
              content
            }
          }
        }
      }
    }
  `);
  const terms = contentQuery.site.siteMetadata.terms;
  const updated = terms.updateDate;
  const content = terms.content;
  const CreateContent = () => {
    const result = content.map((item, ind) => {
      if (item.type === "bold") {
        return (
          <Typography className={classes.boldText} key={ind}>
            {item.content}
          </Typography>
        );
      } else if (item.type === "normal") {
        return (
          <Typography className={classes.text} key={ind}>
            {item.content}
          </Typography>
        );
      }
    });
    return <div>{result}</div>;
  };

  return (
    <div className={classes.container}>
      <Header back={props.termsClick}>Terms of use</Header>
      <Box>
        <Typography className={classes.boxText}>
          Last updated: {updated}
        </Typography>
      </Box>
      <CreateContent />
    </div>
  );
}
